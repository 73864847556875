import * as Environment from "../base/Environment.js";

import {AnimatableSprite} from "../base/Display2D.js";
import {SlidingSpritePrototype} from "../components/Control.js";

import {SiteSection} from "./SiteSection.js";

//
// DetailDrawings extends SiteSection
//

export const DetailDrawings = function (context) {
	SiteSection.apply (this, arguments);
	
};

window.DetailDrawings = DetailDrawings;

DetailDrawings.prototype = Object.create (SiteSection.prototype);

DetailDrawings.prototype.takeElement = function (element) {
	SiteSection.prototype.takeElement.apply (this, arguments);
	
	const filters = this.filters = element.querySelectorAll (".download-filter");
	
	filters.forEach (filter => {
		const hasDefault = filter.hasDefault = !!filter.querySelector ("[data-filter-value=\"*\"]");
		
		const buttons = filter.querySelectorAll (".button");
		buttons.forEach (button => {
			button.addEventListener ("click", function (event) {
				this.clickFilterButton (event);
				
			}.bind (this));
			
		});
		
	});
	
	const itemsContainer = this.itemsContainer = element.querySelector (".datail-drawing-items");
	const items = this.items = element.querySelectorAll ("[data-preview]");
	
	items.forEach (item => {
		item.children [1].addEventListener ("click", function (event) {
			this.openItem (item, event.currentTarget)
			
		}.bind (this));
		
	});
	
	this.updateFilterResults ();
	
	itemsContainer.classList.remove ("invisible");
	
	if (Environment.FAST_PASS) {
		window.setTimeout (function () {

			this.clickFilterButton ({currentTarget: filters [0].children [1].children [0]});
			this.clickFilterButton ({currentTarget: filters [1].children [1].children [1]});
			/*
			
			this.clickFilterButton ({currentTarget: filters [0].children [1].children [0]});
			if (filters.length == 4)
				this.clickFilterButton ({currentTarget: filters [1].children [1].children [0]});
			*/
			
			 this.openItem (this.filteredItems [0], this.filteredItems [0].children [1]);
			
		}.bind (this), 750);
		
	}
	
};

DetailDrawings.prototype.clickFilterButton = function (event) {
	const clickedButton = event.currentTarget;
	
	const filter = clickedButton.parentNode.parentNode;
	const buttons = filter.querySelectorAll (".button");
	
	buttons.forEach (button => {
		if (button == clickedButton)
			button.classList.add ("active");
		else
			button.classList.remove ("active");
		
	});
	
	this.updateFilterResults ();
	
};

DetailDrawings.prototype.updateFilterResults = function () {
	const filters = this.filters;
	const filterMask = new Object ();
	
	let hasSelectedAllFiltersWithNoDefault = true;
	let didSelectAnyFilterWithNoDefault = false;
	
	filters.forEach (filter => {
		const key = filter.dataset.filterKey;
		const value = filter.querySelector (".active")?.dataset.filterValue;
		
		if (value) {
			filterMask [key] = value;
			
			if (!filter.hasDefault)
				didSelectAnyFilterWithNoDefault = true;
			
		} else if (!filter.hasDefault) {
			hasSelectedAllFiltersWithNoDefault = false;
			filterMask [key] = "*";
			
		}
		
	});
	
	let items = this.items;
	
	filters.forEach (filter => {
		const key = filter.dataset.filterKey;
		let value = filterMask [key];
		
		const itemValueMap = new Object ();
		
		items.forEach (item => {
			const itemValue = item.dataset [key];
			itemValueMap [itemValue] = true;
			
		});
		
		let numOptions = 0;
		
		const buttons = filter.querySelectorAll (".button");
		buttons.forEach (button => {
			const value = button.dataset.filterValue;
			button.style.display =
				value == "*" || itemValueMap [value] ? "" : "none";
			
			if (itemValueMap [value])
				numOptions++;
			
		});
		
		// trace ("key", key, "nomop", numOptions);
		
		if (numOptions <= 1)
			value = filterMask [key] = "*";
		
		if (hasSelectedAllFiltersWithNoDefault)
			filter.style.display = numOptions > 1 ? "flex" : "none";
		
		const prefilteredItems = new Array ();
		
		items.forEach (item => {
			const itemValue = item.dataset [key];
			
			if (value == "*" ||
				value == itemValue)
				prefilteredItems.push (item);
			else
				item.style.display = "none";
			
		});
		
		items = prefilteredItems;
		
	})
	
	const filteredItems = this.filteredItems = new Array ();
	
	items.forEach (item => {
		let fitsMask = true;
		
		for (const key in filterMask) {
			const value = filterMask [key];
			
			if (value == "*")
				continue;
			
			if (item.dataset [key] != value) {
				fitsMask = false;
				break;
				
			}
			
		}
		
		item.style.display = fitsMask ? "" : "none";
		
		if (fitsMask)
			filteredItems.push (item);
		
	});
	
	this.itemsContainer.style.display = didSelectAnyFilterWithNoDefault && filteredItems.length ? "" : "none";
	
};

DetailDrawings.prototype.openItem = function (item, sourceElement) {
	let overlay = this.overlay;
	
	if (!overlay) {
		overlay = this.overlay = this.attachSprite (DetailDrawingsOverlay);
		overlay.takeElement (this.element.querySelector (".datail-drawing-overlay"));
		
	}
	
	overlay.openFromSourceElement (sourceElement, Array.from (this.filteredItems).indexOf (item));
	
};

//
// DetailDrawingsOverlay extends AnimatableSprite
//

export const DetailDrawingsOverlay = function (context) {
	AnimatableSprite.apply (this, arguments);
	
};

DetailDrawingsOverlay.prototype = Object.create (AnimatableSprite.prototype);

DetailDrawingsOverlay.prototype.renderInContext = function () {};

DetailDrawingsOverlay.prototype.takeElement = function (element) {
	this.element.remove ();
	this.element = element;
	
	const background = this.background = element.querySelector (".datail-drawing-overlay-background");
	const controls = this.controls = element.querySelector (".datail-drawing-overlay-controls");
	
	controls.addEventListener ("click", this.clickCloseButton.bind (this));
	
	const contentElement = this.contentElement = element.querySelector (".datail-drawing-overlay-content");
	
	const gallery = this.gallery = this.attachSprite (DetailDrawingsGallery);
	gallery.takeElement (contentElement.querySelector (".column"));
	
	this.keyDown = this.keyDown.bind (this);
	
};

DetailDrawingsOverlay.prototype.openFromSourceElement = function (sourceElement, itemIndex) {
	this.sourceElement = sourceElement;
	
	document.scrollingElement.style.overflow = "hidden";
	document.body.addEventListener ("keydown", this.keyDown);
	
	const gallery = this.gallery;
	const filteredItems = this.parent.filteredItems || this.parent.items;
	
	const slides = new Array ();
	
	filteredItems.forEach (item => {
		const slideElement = document.createElement ("img");
		
		const slide = new DetailDrawingsGallerySlide (this.element);
		slide.takeElement (slideElement);
		
		slide.item = item;
		
		slides.push (slide);
		
	});
	
	gallery.takeSlides (slides, itemIndex);
	
	this.startAnimation ("Expansion", {direction: 1, rate: .05});
	
	window.setTimeout (function () {
		// this.clickCloseButton ();
		
	}.bind (this), 1000);
	
};

DetailDrawingsOverlay.prototype.animateExpansion = function () {
	const state = this.updatedState ("Expansion");
	let t = state.phase;
	
	this.element.style.display = t ? "" : "none";
	
	if (state.direction) {
		t = 1 - t;
		t = 1 - t * t;
		
	} else {
		t = t * t;
		
	}
	
	t = .5 - Math.cos (t * Math.PI) * .5;
	const t_ = 1 - t;
	
	const sourceElement = this.sourceElement;
	const contentElement = this.contentElement;
	const background = this.background;
	const controls = this.controls;
	
	if (t < 1) {
		const previewBounds = sourceElement.getBoundingClientRect ();
		const viewportSize = this.getStage ().size;
		
		const rect = [
			t_ * previewBounds.left,
			t_ * previewBounds.top,
			t_ * previewBounds.width + t * viewportSize [0],
			t_ * previewBounds.height + t * viewportSize [1]
			
		];
		
		background.style.left = rect [0] + "px";
		background.style.top = rect [1] + "px";
		background.style.width = rect [2] + "px";
		background.style.height = rect [3] + "px";
		
		background.style.opacity = t * 4;
		contentElement.style.opacity = t * 4;
		
		const center = [
			rect [0] + rect [2] * .5,
			rect [1] + rect [3] * .5
			
		];
		
		const previewScale = Math.min (
			previewBounds.width / viewportSize [0],
			previewBounds.height / viewportSize [1]
			
		);
		
		contentElement.style.transform =
			"translate3d(" + (center [0] - viewportSize [0] * .5) + "px, " + (center [1] - viewportSize [1] * .5) + "px, 0) " +
			"scale(" + (previewScale * t_ + t) + ")";
		
		controls.style.opacity = t * 8 - 7;
		
	} else {
		background.style.left = "";
		background.style.top = "";
		background.style.width = "";
		background.style.height = "";
		
		background.style.opacity = 1;
		contentElement.style.transform = "";
		contentElement.style.opacity = "";
		controls.style.opacity = "";
		
	}
	
	this.element.style.display = t ? "" : "none";

};

DetailDrawingsOverlay.prototype.keyDown = function (event) {
	var keyCode = event.keyCode;
	switch (keyCode) {
		case 27:
			this.clickCloseButton ();
			break;
			
	}
	
};

DetailDrawingsOverlay.prototype.clickCloseButton = function (event) {
	if (event)
		event.preventDefault ();
	
	document.scrollingElement.style.overflow = "";
	document.body.removeEventListener ("keydown", this.keyDown);
	
	this.startAnimation ("Expansion", {direction: 0, rate: .05});
	
};

//
// DetailDrawingsGallery extends AnimatableSprite, SlidingSpritePrototype
//

export const DetailDrawingsGallery = function (context) {
	AnimatableSprite.apply (this, arguments);
	
};

DetailDrawingsGallery.prototype = Object.create (AnimatableSprite.prototype);
DetailDrawingsGallery.extendPrototype (SlidingSpritePrototype);

DetailDrawingsGallery.prototype.getListenerTargetForScrollingController = function () {
	return this.element;
	
};

DetailDrawingsGallery.prototype.takeElement = function (element) {
	this.element.parentNode.removeChild (this.element);
	this.element = element;
	
	SlidingSpritePrototype.apply (this, arguments);
	
	const scrollingController = this.scrollingController;
	
	scrollingController.stage = this.getStage ();
	scrollingController.getMouseListenerTarget = function (eventType) {
		return element;
		
	};
	
	scrollingController.addListener ("beginDrag", this.beginDrag, this);
	scrollingController.addListener ("dragHorizontal", this.dragHorizontal, this);
	scrollingController.addListener ("cancelDragHorizontal", this.endDragHorizontal, this);
	scrollingController.addListener ("endDrag", this.endDragHorizontal, this);
	
	this.addListener ("completeSliding", this.completeSliding, this);
	this.addListener ("advance", this.advance, this);
	
	var navigationElement = this.navigationElement = element.parentNode.querySelector (".datail-drawing-navigation");
	var arrowButtons = navigationElement.querySelectorAll (".datail-drawing-navigation-button");
	
	if (arrowButtons.length >= 2) {
		arrowButtons [0].addEventListener ("click", function () {
			this.advanceInDirection (-1, true);
			this.markActivity ();
			
		}.bind (this));
		arrowButtons [0].addEventListener ("touchstart", function () {
			this.advanceInDirection (-1, true);
			this.markActivity ();
			
		}.bind (this));
		arrowButtons [1].addEventListener ("click", function () {
			this.advanceInDirection (1, true);
			this.markActivity ();
			
		}.bind (this));
		arrowButtons [1].addEventListener ("touchstart", function () {
			this.advanceInDirection (1, true);
			this.markActivity ();
			
		}.bind (this));
		
		function stopPropagation (event) {
			event.stopPropagation ();
			event.preventDefault ();
			
		}
		
		for (var i = arrowButtons.length; i--;) {
			var arrowButton = arrowButtons [i];
			arrowButton.addEventListener ("mousedown", stopPropagation);
			arrowButton.addEventListener ("touchstart", stopPropagation);
			
		}
		
	}
	
	const idField = this.idField = element.parentNode.querySelector (".datail-drawing-id");
	const nameField = this.nameField = element.parentNode.querySelector (".datail-drawing-name");
	const downloadField = this.downloadField = element.parentNode.querySelector (".datail-drawing-downloads :last-child");
	
	var contentContainer = this.contentContainer = this.attachSprite ();
	element.removeChild (contentContainer.element);
	contentContainer.element = element.querySelector ("div");
	
	new ResizeObserver (this.updateLayout.bind (this)).observe (element);
	
};

DetailDrawingsGallery.prototype.takeSlides = function (slides, itemIndex) {
	this.viewSize = undefined;
	this.initialItemIndex = itemIndex;
	
	this.slides = slides;
	var numSlides = this.numSlides = slides.length;
	
	var contentContainer = this.contentContainer;
	contentContainer.element.innerHTML = "";
	
	slides.forEach (slide => {
		contentContainer.addChild (slide);
		
	});
	
	this.finishSetup ();
	
};

DetailDrawingsGallery.prototype.finishSetup = function (viewSize) {
	const scrollingController = this.scrollingController;
	var navigationElement = this.navigationElement;
	
	const numSlides = this.numSlides;
	
	if (numSlides > 1) {
		scrollingController.awake ();
		navigationElement.style.display = "";
		
		if (Environment.FAST_PASS) {
			window.setTimeout (function () {
				this.advanceInDirection (1, true);
				
			}.bind (this), 500);
			
		}
		
	} else {
		scrollingController.sleep ();
		navigationElement.style.display = "none";
		
	}
	
};

DetailDrawingsGallery.prototype.updateLayout = function (noResize) {
	this.spacing = Site.sharedInstance.gutterWidth;
	
	var containerElement = this.contentContainer.element;
	var viewSize = [
		Math.round (containerElement.offsetWidth),
		Math.round (containerElement.offsetHeight)
		
	];
	
	this.setViewSize (viewSize, noResize);
	
};

DetailDrawingsGallery.prototype.setViewSize = function (viewSize, noResize) {
	const lastViewSize = this.viewSize;
	const lastIndex = lastViewSize && this.slideIndexForViewOffset (this.viewOffset);
	
	this.viewSize = viewSize;
	this.setSize (viewSize);
	
	this.viewOffset = this.currentViewOffset = this.viewOffsetForSlideIndex (lastViewSize ? lastIndex : this.initialItemIndex);
	this.removeRunLoopHandler ("processSliding");
	
	this.updateViewOffset ();
	
	if (!lastViewSize) {
		this.dispatchEvent ("advance");
		this.dispatchEvent ("completeSliding");
		
	}
	
};

DetailDrawingsGallery.DRAG_DELTA = 150;

DetailDrawingsGallery.prototype.beginDrag = function (scrollingController) {
	SlidingSpritePrototype.beginDrag.apply (this, arguments);
	
	// scrollingController.currentEvent.preventDefault ();
	scrollingController.currentEvent.stopPropagation ();
	
	delete this.maxScrollSpeed;
	
};

DetailDrawingsGallery.prototype.boundedViewOffset = function (viewOffset) {
	return viewOffset;
	
};

DetailDrawingsGallery.prototype.snapToBounds = function () {
	var viewOffset = this.viewOffset;
	var index = this.slideIndexForViewOffset (viewOffset, true);
	
	var targetViewOffset = this.viewOffsetForSlideIndex (index);
	
	if (viewOffset != targetViewOffset) {
		this.viewOffset = targetViewOffset;
		this.slidingInertia = .85;
		this.addRunLoopHandler ("processSliding");
		
	}
	
};

DetailDrawingsGallery.prototype.completeSliding = function (sender) {
	const slides = this.slides;
	var index = this.slideIndexForViewOffset (this.viewOffset);
	
	index %= slides.length;
	if (index < 0)
		index += slides.length;
	
	var currentSlide = slides [index];
	if (!currentSlide.isLoading)
		currentSlide.startLoading ();
	
	if (slides.length > 1) {
		var nextItem = slides [(index + 1) % slides.length];
		if (!nextItem.isLoading)
			nextItem.startLoading ();
		
	}
	
	if (slides.length > 2) {
		var previousItem = slides [(index + slides.length - 1) % slides.length];
		if (!previousItem.isLoading)
			previousItem.startLoading ();
		
	}
	
};

DetailDrawingsGallery.prototype.advance = function (sender) {
	// this.setCurrentItem (this.slides [this.slideIndexForViewOffset (this.viewOffset)].item);
	
};

DetailDrawingsGallery.prototype.setCurrentItem = function (currentItem) {
	if (this.currentItem == currentItem)
		return;
	
	this.currentItem = currentItem;
	
	this.idField.textContent = currentItem.children [0].textContent;
	this.nameField.textContent = currentItem.children [1].textContent;
	this.downloadField.innerHTML = currentItem.children [2].firstElementChild.innerHTML;
	
};

DetailDrawingsGallery.prototype.dragHorizontal = function (scrollingController) {
	var deltaX = scrollingController.delta.x;
	this.removeRunLoopHandler ("processSliding");
	
	var viewSize = this.viewSize;
	var dragDelta = Math.min (
		viewSize [0] / 2, DetailDrawingsGallery.DRAG_DELTA
		
	);
	
	if (Math.abs (deltaX) >= dragDelta && !this.scrollThrough)
		this.advanceInDirection (deltaX < 0 ? -1 : 1);
	else
		this.viewOffset = this.currentViewOffset = this.startViewOffset + deltaX;
	
	this.updateViewOffset ();
	this.markActivity ();
	
};

DetailDrawingsGallery.prototype.endDragHorizontal = function (scrollingController) {
	var didFlick = scrollingController.didFlickHorizontal;
	
	if (didFlick) {
		var targetViewOffset = this.viewOffset + scrollingController.flickDeltaX * 1.5;
		
		var viewSize = this.viewSize;
		var dragDelta = Math.min (
			viewSize [0] / 2, DetailDrawingsGallery.DRAG_DELTA
			
		);
		
		if (this.scrollThrough) {
			this.viewOffset = targetViewOffset;
			this.snapToBounds ();
			
			this.dispatchEvent ("advance");
			
		} else {
			if (Math.abs (targetViewOffset) >= dragDelta) {
				this.advanceInDirection (scrollingController.flickDeltaX < 0 ? -1 : 1);
				
			} else {
				this.snapToBounds ();
				
			}
			
		}
		
	} else {
		this.snapToBounds ();
		
	}
	
};

DetailDrawingsGallery.prototype.advanceInDirection = function (direction, easeIn) {
	if (this.scrollingController.isTouching)
		this.releaseDrag ();
	
	var delta = Math.abs (this.currentViewOffset - this.viewOffset);
	
	var index = this.slideIndexForViewOffset (this.viewOffset, true) + direction;
	
	var viewSize = this.viewSize;
	this.viewOffset = this.viewOffsetForSlideIndex (index);
	
	this.slidingInertia = .85;
	if (easeIn && delta < 10)
		this.maxScrollSpeed = 4;
	this.accelerationDescription = undefined;
	
	this.addRunLoopHandler ("processSliding");
	
	this.dispatchEvent ("advance");
	
};

DetailDrawingsGallery.prototype.markActivity = function () {
	this.autoRotationDelay = this.autoRotationFrequency * 1.5;
	
};

DetailDrawingsGallery.prototype.spacing = 40;

DetailDrawingsGallery.prototype.slideIndexForViewOffset = function (viewOffset, noWrap) {
	var index = Math.round (
		viewOffset / (this.viewSize [0] + this.spacing)
		
	);
	
	if (!noWrap) {
		var slides = this.slides;
		const numSlides = this.numSlides;
		
		index = index % numSlides;
		if (index < 0)
			index += numSlides;
		
	}
	return index;
	
};

DetailDrawingsGallery.prototype.setViewOffsetByIndex = function (index, dontAnimate) {
	var slides = this.slides;
	const numSlides = this.numSlides;
	
	index = index % numSlides;
	if (index < 0)
		index += numSlides;
	
	var viewOffset = this.viewOffset;
	
	var currentImageIndex = this.slideIndexForViewOffset (this.viewOffset);
	var delta = (index - currentImageIndex) % numSlides;
	if (delta < 0)
		delta += numSlides;
	
	if (delta > numSlides * .5)
		delta = delta - numSlides;
	
	// trace ("delta", delta, currentImageIndex);
	if (!delta)
		return;
	
	var viewSize = this.viewSize;
	var targetViewOffset = this.viewOffset;
	
	var direction = numSlides > 2 ? delta > 0 ? 1 : -1 : currentImageIndex > index ? -1 : 1;
	targetViewOffset += (this.viewSize [0] + this.spacing) * Math.abs (delta) * direction;

	if (viewOffset != targetViewOffset) {
		if (dontAnimate) {
			this.viewOffset = this.currentViewOffset = targetViewOffset;
			this.removeRunLoopHandler ("processSliding");
			this.updateViewOffset ();
			
		} else {
			this.viewOffset = targetViewOffset;
			this.slidingInertia = .85;
			this.maxScrollSpeed = 4;
			this.accelerationDescription = undefined;
			this.addRunLoopHandler ("processSliding");
			
		}
		
	}
	
	this.dispatchEvent ("advance");

};

DetailDrawingsGallery.prototype.viewOffsetForSlideIndex = function (slideIndex) {
	if (this.adjustHeightToContent) {
		const slides = this.slides;
		const numSlides = this.numSlides;
		
		const hardOff = Math.floor (
			slideIndex / numSlides
			
		);
		
		slideIndex = slideIndex % numSlides;
		if (slideIndex < 0)
			slideIndex += numSlides;
		
		let cursor = 0;
		
		for (var i = 0; i < slideIndex; i++) {
			const slide = slides [i];
			cursor += slide.contentSize [0] + this.spacing;
			
		}
		
		return cursor + hardOff * this.fullWidth;
		
	} else {
		return slideIndex * (this.viewSize [0] + this.spacing);
		
	}
	
};

DetailDrawingsGallery.prototype.updateViewOffset = function () {
	var slides = this.slides;
	if (!slides.length)
		return;
	
	var viewSize = this.viewSize;
	var viewWidth = viewSize [0];
	var spacing = this.spacing;
	
	var viewOffset = this.currentViewOffset;
	var currentSlideIndex = this.currentSlideIndex = this.slideIndexForViewOffset (viewOffset);
	
	viewOffset = (viewOffset + (viewWidth + spacing) * .5) % (viewWidth + spacing);
	if (viewOffset < 0)
		viewOffset += (viewWidth + spacing);
	
	viewOffset = viewOffset - (viewWidth + spacing) * .5 + currentSlideIndex * (viewWidth + spacing);
	
	viewOffset = Math.round (viewOffset);
	
	for (var i = slides.length; i--;) {
		var slide = slides [i];
		slide.isUsed = false;
		
	}
	
	var overscan = 0; // this.overscan;
	
	var slide = slides [currentSlideIndex];
	
	var i = currentSlideIndex;
	var cursor = -Math.round (viewSize [0] * .5) - viewOffset + (currentSlideIndex * (viewWidth + spacing));
	
	while (cursor > -viewWidth * .5 + spacing - overscan) {
		i = (i - 1) % slides.length;
		if (i < 0)
			i += slides.length;
		
		var slide = slides [i];
		// slide.setViewSize (viewSize);
		
		cursor -= (slide.contentSize || viewSize) [0] + spacing;
		
	}
	
	var viewOffset = Math.round (viewWidth * .5);
	
	var contentContainer = this.contentContainer;
	
	if (this.numSlides == 1)
		cursor = -viewOffset;
	
	while (cursor < Math.floor (viewWidth * .5) + overscan) {
		var slide = slides [i];
		if (slide.isUsed)
			break;
		slide.isUsed = true;
		
		slide.setViewSize (viewSize);
		slide.setPosition (
			cursor + viewOffset,
			0
			
		);
		
		// slide.markLoadingPriority (-(cursor + viewOffset) / 8192) + 4.5;
		if (!slide.isLoading)
			slide.startLoading ();
		
		slide.loadingPriority = Math.abs (cursor + viewOffset);
		
		if (!slide.isAwake) {
			slide.setAlpha (1);
			slide.awake ();
			
		}
		
		var percentage = Math.max (0, Math.min (1, (1 - Math.abs (
			slide.position [0] / viewSize [0]
			
		))));
		percentage = (percentage - .5) * 2;
		percentage = (percentage - .515) * 2.25;
		
		if (i == currentSlideIndex) {
			this.idField.style.opacity = percentage;
			this.nameField.style.opacity = percentage;
			this.downloadField.parentNode.style.opacity = percentage;
			
			this.setCurrentItem (slide.item);
			
		}
		
		slide.setAlpha (1); // percentage * .67 + .33);
		
		if (slide.setPercentage) {
			slide.setPercentage (percentage);
			
		}
		
		i = (i + 1) % slides.length;
		if (i < 0)
			i += slides.length;
		
		cursor += (slide.contentSize || viewSize) [0] + spacing;
		
	}
	
	for (var i = slides.length; i--;) {
		var slide = slides [i];
		if (!slide.isUsed) {
			// contentContainer.removeChild (slide);
			slide.setAlpha (0);
			slide.element.style.display = "none";
			
			if (slide.isAwake)
				slide.sleep ();
			
		}
		
	}
	
	this.dispatchEvent ("updateViewOffset");
	
	this.renderInContext (this.context);
	
};

//
// DetailDrawingsGallerySlide extends AnimatableSprite
//

const DetailDrawingsGallerySlide = function (context) {
	AnimatableSprite.apply (this, arguments);
	
	this.renderUsingCSSTransform = true;
	
};

DetailDrawingsGallerySlide.prototype = Object.create (AnimatableSprite.prototype);

DetailDrawingsGallerySlide.prototype.takeElement = function (element) {
	this.element = element;
	element.classList.add ("unselectable");
	
	const img = element.querySelector ("img, object, embed");
	if (img) {
		img.setAttribute ("draggable", "false");
		img.style.userSelect = "none";
		img.style.webkitTouchCallout = "none";
		
	}
	
};

DetailDrawingsGallerySlide.prototype.setViewSize = function (viewSize) {
	this.viewSize = viewSize;
	
	var element = this.element;
	element.style.width = viewSize [0] + "px";
	element.style.height = viewSize [1] + "px";
	
	var image = this.image;
	if (!image)
		return;
	
	image.style.width = viewSize [0] + "px";
	image.style.height = viewSize [1] + "px";
	
};

DetailDrawingsGallerySlide.prototype.markLoadingPriority = function (loadingPriority) {
	this.loadingPriority = loadingPriority;
	
};

DetailDrawingsGallerySlide.prototype.startLoading = function () {
	this.isLoading = true;
	
	const element = this.element;
	const image = element || element.querySelector ("img");
	
	const item = this.item;
	image.src = item.dataset.preview;
	
};

DetailDrawingsGallerySlide.prototype.awake = function () {
	this.isAwake = true;
	
};

DetailDrawingsGallerySlide.prototype.sleep = function () {
	this.isAwake = false;
	
};
