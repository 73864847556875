import * as Environment from "../base/Environment.js";

import {AnimatableSprite} from "../base/Display2D.js";
import {SlidingSpritePrototype} from "../components/Control.js";

import {LazyLoader} from "../components/LazyLoader.js";

import {SiteSection} from "./SiteSection.js";

//
// XLTeaserSliderSection extends SiteSection
//

export const XLTeaserSliderSection = function (context) {
	SiteSection.apply (this, arguments);
	
};

window.XLTeaserSliderSection = XLTeaserSliderSection;

XLTeaserSliderSection.prototype = Object.create (SiteSection.prototype);

XLTeaserSliderSection.prototype.takeElement = function (element) {
	SiteSection.prototype.takeElement.apply (this, arguments);
	
	var gallery = this.gallery = this.attachSprite (Gallery);
	
	gallery.takeElement (element);
	
};

XLTeaserSliderSection.prototype.awake = function () {
	SiteSection.prototype.awake.apply (this, arguments);
	
	var gallery = this.gallery;
	gallery.isAutoRotationLocked = false;
	
};

XLTeaserSliderSection.prototype.sleep = function () {
	SiteSection.prototype.sleep.apply (this, arguments);
	
	var gallery = this.gallery;
	gallery.isAutoRotationLocked = true;
	
};

XLTeaserSliderSection.prototype.setViewSize = function (viewSize) {
	SiteSection.prototype.setViewSize.apply (this, arguments);
	
	var gallery = this.gallery;
	gallery.updateLayout ();
	
	window.setTimeout (function () {
		gallery.updateLayout ();
		gallery.contentContainer.element.style.visibility = "";
		
	}, 50);
	
};

//
// Gallery extends AnimatableSprite, SlidingSpritePrototype
//

const Gallery = function (context) {
	AnimatableSprite.apply (this, arguments);
	
};

Gallery.prototype = Object.create (AnimatableSprite.prototype);
Gallery.extendPrototype (SlidingSpritePrototype);

Gallery.prototype.getListenerTargetForScrollingController = function () {
	return this.element;
	
};

Gallery.prototype.takeElement = function (element) {
	this.element.parentNode.removeChild (this.element);
	this.element = element;
	
	if (!Environment.IS_IN_NEOS_EDITOR)
		element.classList.add ("unselectable");
	
	SlidingSpritePrototype.apply (this, arguments);
	
	var fitting = this.fitting = element.getAttribute ("data-fitting");
	var adjustHeightToContent = this.adjustHeightToContent = fitting == "content";
	if (!adjustHeightToContent) {
		fitting = fitting.split (":");
		var aspectRatio = this.aspectRatio =
			parseFloat (fitting [1]) /
			parseFloat (fitting [0]);
		
	}
	
	var contentContainer = this.contentContainer = this.attachSprite ();
	
	element.removeChild (contentContainer.element);
	contentContainer.element = element.querySelector (".gallery__slide-container");
	
	this.parseChildren ();
	
	this.finishSetup ();
	
};

Gallery.prototype.parseChildren = function () {
	var element = this.element;
	var context = this.context;
	
	var slides = this.slides = new Array ();
	
	var contentContainer = this.contentContainer;
	var children = (Environment.IS_IN_NEOS_EDITOR ? element : element).querySelectorAll (".gallery__slide");
	
	var numSlides = this.numSlides = children.length;
	
	var firstChildParent = children [0];
	
	firstChildParent = firstChildParent && firstChildParent.slide;
	firstChildParent = firstChildParent && firstChildParent.element.parentNode;
	
	for (var i = 0; i < numSlides; i++) {
		var child = children [i];
		
		var slide = child.slide;
		if (child.slide) {
			slides.push (slide);
			
		} else {
			slide = child.slide = new GallerySlide (context);
			slide.parentGallery = this;
			
			slide.takeElement (child);
			slide.setAlpha (0);
			
			slides.push (slide);
			
		}
		
	}
	
	for (var i = 0; i < slides.length; i++) {
		var slide = slides [i];
		slide.index = i;
		
	}
	
	if (isNaN (this.currentIndex))
		this.setCurrentIndex (0);
	
	this.setProgressBarIndicators ();
	
	
	if (numSlides > 1 && !Environment.IS_IN_NEOS_EDITOR) {
		var scrollingController = this.scrollingController;
		
		scrollingController.stage = this.getStage ();
		scrollingController.getMouseListenerTarget = function (eventType) {
			return element;
			
		};
		
		scrollingController.awake ();
		
		scrollingController.addListener ("beginDrag", this.beginDrag, this);
		scrollingController.addListener ("dragHorizontal", this.dragHorizontal, this);
		scrollingController.addListener ("cancelDragHorizontal", this.endDragHorizontal, this);
		scrollingController.addListener ("endDrag", this.endDragHorizontal, this);
		
	}
	
};

Gallery.prototype.setProgressBarIndicators = function () {
	const progressBar = this.progressBar = this.element.querySelector (".gallery__navigation__progress-bar");
	
	if (progressBar) {
		progressBar.innerHTML = "";
		
		const indicators = this.indicators = new Array ();
		const numSlides = this.numSlides;
		
		for (let i = 0; i < numSlides; i++) {
			const indicator = document.createElement ("div");
			indicator.index = i;
			indicator.classList.add ("gallery__navigation__progress-bar-item");
			
			if (i == 0)
				indicator.classList.add ("active");
			
			indicator.addEventListener ("mousedown", function (event) {
				event.stopPropagation ();
				if (!Environment.IS_TOUCH_DEVICE)
					event.preventDefault ();
				
			});
			
			indicator.addEventListener ("click", function (event) {
				this.setCurrentIndex (event.currentTarget.index);
				
			}.bind (this));
			
			progressBar.appendChild (indicator);
			indicators.push (indicator);
			
		}
		
	}

};

Gallery.prototype.finishSetup = function (viewSize) {
	var element = this.element;
	
	var navigationElement = this.navigationElement = element.querySelector (".gallery__navigation");
	this.contentContainer.element.appendChild (navigationElement);
	
	const numSlides = this.numSlides;
	
	if (numSlides > 1 || Environment.IS_IN_NEOS_EDITOR) {
		this.setProgressBarIndicators ();
		
		if (navigationElement) {
			var arrowButtons = element.querySelectorAll (".gallery__navigation__arrow");
			arrowButtons [0].addEventListener ("click", function () {
				this.advanceInDirection (-1, true);
				this.markActivity ();
				
			}.bind (this));
			arrowButtons [1].addEventListener ("click", function () {
				this.advanceInDirection (1, true);
				this.markActivity ();
				
			}.bind (this));
			
		}
		
		if (!Environment.IS_IN_NEOS_EDITOR) {
			this.isAutoRotationLocked = true;
			
			var autoRotationFrequency = this.autoRotationFrequency = parseFloat (element.getAttribute ("data-auto-rotation-frequency")) * 60;
			var shouldAutoRotate = this.shouldAutoRotate = !!autoRotationFrequency;
			if (shouldAutoRotate)
				this.startAutoRotation ();
			
		}
		
	}
	
	if (Environment.IS_IN_NEOS_EDITOR) {
		var mutationObserver = new MutationObserver (this.mutateElement.bind (this));
		mutationObserver.observe (this.element, {
			childList: true,
			subtree: true
			
		});
		
	}
	
};

Gallery.prototype.mutateElement = function (stage) {
	if (this.isMutating)
		return;

	var activeElement = document.activeElement;
	if (activeElement && activeElement.getAttribute ("contenteditable") == "true")
		return;
	
	var now = new Date ().getTime ();
	var nextTimeToObserve = this.nextTimeToObserve;
	
	if (now < nextTimeToObserve) {
		if (!this.mutationHandler) {
			this.mutationHandler = window.setTimeout (
				this.handleMutation.bind (this),
				nextTimeToObserve - now
				
			);
			
		}
		
	} else {
		this.handleMutation ();
		
	}
	
};

Gallery.prototype.handleMutation = function () {
	this.mutationHandler = null;
	
	var now = new Date ().getTime ();
	var nextTimeToObserve = this.nextTimeToObserve = now + 500;
	
	this.isMutating = true;
	
	this.parseChildren ();
	this.updateLayout (true);
	
	window.setTimeout (function () {
		this.updateLayout (true);
		
		this.isMutating = false;
		
	}.bind (this), 50);
	
};

Gallery.prototype.startAutoRotation = function () {
	// if (FAST_PASS)
	//	return;
	
	this.addListener ("mouseover", this.mouseOverOnAutoRotation, this);
	this.addListener ("mouseout", this.mouseOutOnAutoRotation, this);

	this.autoRotationDelay = this.autoRotationFrequency;
	this.addRunLoopHandler ("processAutoRotation");
	
};

Gallery.prototype.stopAutoRotation = function () {
	this.removeRunLoopHandler ("processAutoRotation");
	
};

Gallery.prototype.mouseOverOnAutoRotation = function (sender) {
	this.isAutoRotationLocked = true;
	
};

Gallery.prototype.mouseOutOnAutoRotation = function (sender) {
	this.isAutoRotationLocked = false;
	this.autoRotationDelay = Math.max (this.autoRotationFrequency, 200);
	
};

Gallery.prototype.processAutoRotation = function (sender) {
	if (this.isAutoRotationLocked)
		return;
	
	var autoRotationDelay = this.autoRotationDelay = Math.max (
		0, this.autoRotationDelay - this.context.animationTimer.framesDelta
		
	);
	
	if (autoRotationDelay)
		return;
	
	this.autoRotationDelay = this.autoRotationFrequency;
	this.skipTracking = true;
	this.advanceInDirection (1);
	
};

Gallery.prototype.updateLayout = function (noResize) {
	var slides = this.slides;
	var slide = slides [0];
	if (!slide)
		return;
	
	slide.element.style.display = "";
	
	var containerElement = this.contentContainer.element;
	var containerWidth = Math.round (containerElement.offsetWidth);
	
	var viewSize;
	
	if (this.adjustHeightToContent) {
		viewSize = [
			containerWidth,
			Math.round (containerElement.offsetHeight)
			
		];
		
		let fullWidth = 0;
		var maxHeight = 0;
		var slides = this.slides;
		
		for (var i = slides.length; i--;) {
			var slide = slides [i];
			var slideElement = slide.element;
			
			var displayStyle = slideElement.style.display;
			
			slideElement.style.height = "";
			if (displayStyle)
				slideElement.style.display = "";
			
			var slideSize = slide.contentSize = [
				0,
				slideElement.offsetHeight
				
			];
			
			slideElement.style.width = viewSize [0] + "px";
			slideElement.style.height = "100%";
			
			if (displayStyle)
				slideElement.style.display = displayStyle;
			
			maxHeight = Math.max (maxHeight, slideSize [1]);
			
		}
		
		viewSize [1] = maxHeight + parseFloat (window.getComputedStyle (containerElement).paddingTop);
		
	} else {
		viewSize = [
			containerWidth,
			Math.round (containerWidth * this.aspectRatio)
			
		];
		
	}
	
	this.setViewSize (viewSize, noResize);
	
};

Gallery.prototype.setViewSize = function (viewSize, noResize) {
	var containerElement = this.contentContainer.element;
	containerElement.style.height = viewSize [1] + "px";
	
	var stageSize = this.getStage ().size;
	
	this.viewSize = viewSize;
	this.setSize (viewSize);
	
};

Gallery.prototype.advanceInDirection = function (direction) {
	const slides = this.slides;
	
	let currentIndex = (this.currentIndex + direction) % slides.length;
	if (currentIndex < 0)
		currentIndex += slides.length;
	
	this.setCurrentIndex (currentIndex);
	
	this.releaseDrag ();
	this.currentViewOffset = -this.currentViewOffset;
	this.snapToBounds ();
	
}

Gallery.DRAG_DELTA = 150;

Gallery.prototype.beginDrag = function (scrollingController) {
	SlidingSpritePrototype.beginDrag.apply (this, arguments);
	
	// scrollingController.currentEvent.preventDefault ();
	scrollingController.currentEvent.stopPropagation ();
	
	delete this.maxScrollSpeed;
	
};

Gallery.prototype.boundedViewOffset = function (viewOffset) {
	return 0;
	
};

Gallery.prototype.snapToBounds = function () {
	var viewOffset = this.viewOffset;
	var targetViewOffset = 0;
	
	if (viewOffset != targetViewOffset) {
		this.viewOffset = targetViewOffset;
		this.slidingInertia = .85;
		this.addRunLoopHandler ("processSliding");
		
	}
	
};

Gallery.prototype.dragHorizontal = function (scrollingController) {
	var deltaX = scrollingController.delta.x;
	this.removeRunLoopHandler ("processSliding");
	
	var viewSize = this.viewSize;
	var dragDelta = Math.min (
		viewSize [0] / 2, Gallery.DRAG_DELTA
		
	);
	
	if (Math.abs (deltaX) >= dragDelta && !this.scrollThrough)
		this.advanceInDirection (deltaX < 0 ? -1 : 1);
	else
		this.viewOffset = this.currentViewOffset = this.startViewOffset + deltaX;
	
	this.updateViewOffset ();
	this.markActivity ();
	
};

Gallery.prototype.endDragHorizontal = function (scrollingController) {
	this.snapToBounds ();
	
};

Gallery.prototype.updateViewOffset = function () {
	const currentViewOffset = this.currentViewOffset;
	const viewOffset = Math.round (this.currentViewOffset * -.25);
	const currentIndex = this.currentIndex;
	
	this.slides.forEach ((slide, i) => {
		const element = slide.element;
		
		element.style.transform = "translateX(" + viewOffset + "px)";
		
		let opacity = 1 - (Math.max (0, Math.abs (currentViewOffset / Gallery.DRAG_DELTA)));
		slide.element.style.opacity = opacity * 1.5;
		slide.element.style.display = i == currentIndex ? "" : "none";
		
	});
	
};

Gallery.prototype.setCurrentIndex = function (currentIndex) {
	if (this.currentIndex == currentIndex)
		return;
	
	this.lastIndex = this.currentIndex;
	this.currentIndex = currentIndex;

	this.startAnimation ("FadeOver", {direction: 1, rate: .04, phase: 0});
	
	const indicators = this.indicators;
	if (indicators) {
		for (let i = indicators.length; i--;) {
			const indicator = indicators [i];
			
			if (i == currentIndex)
				indicator.classList.add ("active");
			else
				indicator.classList.remove ("active");
			
		}
		
	}
	
};

Gallery.prototype.animateFadeOver = function () {
	const state = this.updatedState ("FadeOver");
	
	if (this.currentViewOffset)
		return;
	
	const t = state.phase;
	const t_ = 1 - t;
	
	const lastIndex = this.lastIndex;
	const currentIndex = this.currentIndex;
	
	const slides = this.slides;
	for (let i = slides.length; i--;) {
		const slide = slides [i];
		
		if (i == lastIndex) {
			slide.element.style.zIndex = 0;
			slide.element.style.opacity = 1;
			slide.element.style.display = t_ ? "" : "none";
			
		} else if (i == currentIndex) {
			slide.element.style.zIndex = 1;
			slide.element.style.opacity = t;
			slide.element.style.display = "";
			
		} else {
			slide.element.style.display = "none";
			
		}
		
	}
	
	
};


Gallery.prototype.markActivity = function () {
	this.autoRotationDelay = this.autoRotationFrequency * 1.5;
	
};

//
// GallerySlide extends AnimatableSprite
//

const GallerySlide = function (context) {
	AnimatableSprite.apply (this, arguments);
	
};

GallerySlide.prototype = Object.create (AnimatableSprite.prototype);

GallerySlide.prototype.takeElement = function (element) {
	this.element = element;
	
	if (Environment.IS_IN_NEOS_EDITOR)
		element.removeAttribute ("href");
	
};
